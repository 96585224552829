<template>
    <div class="container">
        <h1 class="text-center" v-html="$t('title')"></h1>
        
        
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 text-center">
                </div>
                <div class="col-12 c-card-wrap">
                    <b-card  v-if="message !== ''">
                        <p>{{message}}</p>
                        <router-link :to="{name: 'homepage'}" class=""><b>{{ $t('registrationConfirm.login') }}</b></router-link>
                    </b-card>
                    <b-card bg-variant="danger " v-if="message_err !== ''">
                        <p>{{message_err}}</p>
                    </b-card>
                </div>
            </div>
        </div>
    
    
    </div>
</template>

<script>
export default {
    name: "registration-confirm",
    data() {
      return {
          message: '',
          message_err: '',
          id: this.$route.params.id,
      }
    },
    async mounted() {
        try {
            const response = await this.$http({
                url: process.env.VUE_APP_SERVER + 'api/users/registration-confirm.php',
                headers: {'content-type': 'application/x-www-form-urlencoded'},
                data: {'hash': this.id},
                method: 'PUT'
            });
            this.message = response.data.message;
        } catch (error) {
            console.error("error", error);
            this.message_err = error.response.data.message;
        }
    }
}
</script>

<style scoped>

</style>
